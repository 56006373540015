// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open Sans:100,300,400,500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  font-weight: 300;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}

ul.ant-menu {
  height: calc(100% - 64px);
}

#root {
  width: 100vw;
  left: 0px;
  position: absolute;
  top: 0px;
}
`, "",{"version":3,"sources":["webpack://./index.css"],"names":[],"mappings":"AAEA;EACE,0DAA0D;EAC1D,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":["@import \"https://fonts.googleapis.com/css?family=Open%20Sans:100,300,400,500\";\n\nbody {\n  font-family: \"Open Sans\", \"Helvetica\", \"Arial\", sans-serif;\n  line-height: 1.5;\n  font-weight: 300;\n}\n\n.trigger {\n  padding: 0 24px;\n  font-size: 18px;\n  line-height: 64px;\n  cursor: pointer;\n  color: #fff;\n  transition: color 0.3s;\n}\n\n.trigger:hover {\n  color: #1890ff;\n}\n\n.logo {\n  height: 32px;\n  margin: 16px;\n}\n\nul.ant-menu {\n  height: calc(100% - 64px);\n}\n\n#root {\n  width: 100vw;\n  left: 0px;\n  position: absolute;\n  top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
