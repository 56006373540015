import React, { useState, useEffect } from "react";
import { Layout, Card, Button, Typography, Table } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";

const { Content } = Layout;
const { Title, Text } = Typography;

interface HeadcountData {
  date: string;
  newHires: number;
  terminations: number;
  overall: number;
}

const mockHeadcountData: HeadcountData[] = [
  { date: "Oct 23", newHires: 0, terminations: 0, overall: 11 },
  { date: "Nov 23", newHires: 0, terminations: 0, overall: 11 },
  // ... add more mock data for each month
  { date: "Sep 24", newHires: 3, terminations: 1, overall: 13 },
  { date: "Oct 24", newHires: 0, terminations: 1, overall: 11 },
];

const divisionData = [
  { name: "Europe", value: 85.71 },
  { name: "Not specified", value: 14.29 },
];

const locationData = [
  { name: "Berlin", value: 50.0 },
  { name: "London", value: 35.71 },
  { name: "Not specified", value: 14.29 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const HeadcountReportPage: React.FC = () => {
  const [headcountData, setHeadcountData] = useState<HeadcountData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setHeadcountData(mockHeadcountData);
      setLoading(false);
    }, 1000);
  }, []);

  const renderPieChart = (data: any[], title: string) => (
    <Card title={title} style={{ marginTop: "24px" }}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );

  return (
    <Layout>
      <Content style={{ padding: "24px" }}>
        <Card>
          <Title level={3}>Headcount</Title>
          <Button style={{ marginTop: "16px" }}>Filter (1)</Button>

          <Card
            title="What is the headcount across my workforce?"
            style={{ marginTop: "24px" }}
          >
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={headcountData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="newHires" stroke="#82ca9d" />
                <Line type="monotone" dataKey="terminations" stroke="#ff7300" />
                <Line type="monotone" dataKey="overall" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Card>

          {renderPieChart(divisionData, "Headcount by divisions")}
          {renderPieChart(locationData, "Headcount by locations")}
        </Card>
      </Content>
    </Layout>
  );
};

export default HeadcountReportPage;
