import { CognitoUserPool } from "amazon-cognito-identity-js";

const CUSTOMER: Record<string, any> = {
  sotiotech: {
    UserPoolId: "us-east-1_lAFQbyt95",
    ClientId: "78fud4fv8s0gcc8bhb8qmp60ss",
  },
  localhost: {
    UserPoolId: "us-east-1_lAFQbyt95",
    ClientId: "78fud4fv8s0gcc8bhb8qmp60ss",
  },
  app: {
    UserPoolId: "us-east-1_lAFQbyt95",
    ClientId: "78fud4fv8s0gcc8bhb8qmp60ss",
  },
};

const customerName: string =
  window.location.hostname.split(".zealsight.com")[0];
const poolData = CUSTOMER[customerName];
const cognitoUserPool = new CognitoUserPool(poolData);

export default cognitoUserPool;
