// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDu-U9eJF46Q_GGBQFaPqWiwXf0y_VlhiA",
  authDomain: "zealsight-3604.firebaseapp.com",
  projectId: "zealsight-3604",
  storageBucket: "zealsight-3604.appspot.com",
  messagingSenderId: "198073492466",
  appId: "1:198073492466:web:ea0e16de2144336c42816b",
  measurementId: "G-6GEW3VRTYL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
