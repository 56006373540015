import styled from "@emotion/styled";
import { useTheme } from "@src/useTheme";

export const PageHeadingRightActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const PageHeadingContainer = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const PageContent = styled.div`
  padding: 5px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const PaddingContainer = styled.div`
  padding: 10px;
`;
