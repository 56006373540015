import React from "react";
import { Input, Card, Row, Col } from "antd";
import {
  SettingOutlined,
  DollarOutlined,
  BellOutlined,
  ImportOutlined,
  ExportOutlined,
  ApiOutlined,
  LinkOutlined,
  HomeOutlined,
  CalendarOutlined,
  TeamOutlined,
  ApartmentOutlined,
  BankOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  ManOutlined,
  ToolOutlined,
  FormOutlined,
  ScheduleOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
  GlobalOutlined,
  SafetyOutlined,
  FieldBinaryOutlined,
  UserSwitchOutlined,
  PartitionOutlined,
  DislikeOutlined,
  FunnelPlotOutlined,
  FileSearchOutlined,
  MailOutlined,
  AudioOutlined,
  FileProtectOutlined,
  FileWordOutlined,
  TagsOutlined,
  MessageOutlined,
  SolutionOutlined,
  TrophyOutlined,
  RocketOutlined,
  PieChartOutlined,
  BarChartOutlined,
  CustomerServiceOutlined,
  ProfileOutlined,
  LockOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import {
  PageContent,
  PageHeadingContainer,
  PageHeadingRightActions,
} from "../Page.styles";
import {
  SettingsContainer,
  SettingsCategoryTitle,
  SettingsGrid,
  SettingCard,
} from "./Settings.styles";

const { Search } = Input;

interface SettingItem {
  title: string;
  icon: React.ReactNode;
}

interface SettingCategory {
  name: string;
  items: SettingItem[];
}

const settingsData: SettingCategory[] = [
  {
    name: "General",
    items: [
      { title: "General", icon: <SettingOutlined /> },
      { title: "Billing", icon: <DollarOutlined /> },
      { title: "Alerts", icon: <BellOutlined /> },
      { title: "Import", icon: <ImportOutlined /> },
      { title: "Exports", icon: <ExportOutlined /> },
      { title: "Integrations", icon: <ApiOutlined /> },
      { title: "Webhooks", icon: <LinkOutlined /> },
    ],
  },
  {
    name: "Core",
    items: [
      { title: "Home", icon: <HomeOutlined /> },
      { title: "Calendars", icon: <CalendarOutlined /> },
      { title: "Positions", icon: <TeamOutlined /> },
      { title: "Job Levels", icon: <ApartmentOutlined /> },
      { title: "Departments", icon: <BankOutlined /> },
      { title: "Divisions", icon: <PartitionOutlined /> },
      { title: "Locations", icon: <EnvironmentOutlined /> },
      { title: "Holiday policies", icon: <CalendarOutlined /> },
      { title: "Employment types", icon: <IdcardOutlined /> },
      { title: "Genders", icon: <ManOutlined /> },
      { title: "Skills", icon: <ToolOutlined /> },
      { title: "Forms", icon: <FormOutlined /> },
    ],
  },
  {
    name: "HR",
    items: [
      { title: "Leave policies", icon: <ScheduleOutlined /> },
      { title: "Work patterns", icon: <ClockCircleOutlined /> },
      { title: "Compensation", icon: <DollarCircleOutlined /> },
      { title: "Probation policies", icon: <FileTextOutlined /> },
      { title: "Termination reasons", icon: <UserOutlined /> },
      { title: "Termination types", icon: <SafetyCertificateOutlined /> },
      { title: "Documents", icon: <FileTextOutlined /> },
      { title: "Employee fields", icon: <UserOutlined /> },
      { title: "Asset fields", icon: <UserOutlined /> },
      { title: "Asset categories", icon: <ProfileOutlined /> },
    ],
  },
  {
    name: "Recruit",
    items: [
      { title: "Careers Site", icon: <GlobalOutlined /> },
      { title: "GDPR", icon: <SafetyOutlined /> },
      { title: "Vacancy Fields", icon: <FieldBinaryOutlined /> },
      { title: "Candidate Fields", icon: <UserOutlined /> },
      { title: "Pipelines", icon: <PartitionOutlined /> },
      { title: "Disqualify Reasons", icon: <DislikeOutlined /> },
      { title: "Sources", icon: <FunnelPlotOutlined /> },
      { title: "Scorecards", icon: <FileSearchOutlined /> },
      { title: "Email Templates", icon: <MailOutlined /> },
      { title: "Interview Templates", icon: <AudioOutlined /> },
      { title: "Offer Templates", icon: <FileProtectOutlined /> },
      { title: "Resume Templates", icon: <FileWordOutlined /> },
      { title: "Vacancy Tags", icon: <TagsOutlined /> },
      { title: "Candidate Tags", icon: <TagsOutlined /> },
    ],
  },
  {
    name: "Perform",
    items: [
      { title: "1:1 Types", icon: <MessageOutlined /> },
      { title: "1:1 Templates", icon: <FileTextOutlined /> },
      { title: "Competencies", icon: <TrophyOutlined /> },
      { title: "Development Plans", icon: <RocketOutlined /> },
    ],
  },
  {
    name: "Time",
    items: [{ title: "Attendance Policies", icon: <ClockCircleOutlined /> }],
  },
  {
    name: "Pulse",
    items: [
      { title: "Segments", icon: <PieChartOutlined /> },
      { title: "Scales", icon: <BarChartOutlined /> },
    ],
  },
  {
    name: "Desk",
    items: [
      { title: "Safe Speak", icon: <CustomerServiceOutlined /> },
      { title: "Case Categories", icon: <ProfileOutlined /> },
    ],
  },
  {
    name: "Security",
    items: [
      { title: "Roles & Permissions", icon: <LockOutlined /> },
      { title: "Authentications", icon: <KeyOutlined /> },
      { title: "API Keys", icon: <ApiOutlined /> },
    ],
  },
];

const Settings: React.FC = () => {
  return (
    <>
      <PageHeadingContainer>
        <PageHeadingRightActions>
          <Search placeholder="Search settings" style={{ width: 300 }} />
        </PageHeadingRightActions>
      </PageHeadingContainer>

      <PageContent>
        <SettingsContainer>
          {settingsData.map((category, index) => (
            <div key={index}>
              <SettingsCategoryTitle>{category.name}</SettingsCategoryTitle>
              <SettingsGrid>
                <Row gutter={[16, 16]}>
                  {category.items.map((item, itemIndex) => (
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} key={itemIndex}>
                      <SettingCard>
                        <Card.Meta avatar={item.icon} title={item.title} />
                      </SettingCard>
                    </Col>
                  ))}
                </Row>
              </SettingsGrid>
            </div>
          ))}
        </SettingsContainer>
      </PageContent>
    </>
  );
};

export default Settings;
