import React, { useState } from "react";
import { Modal, Form, Input, DatePicker, Select, Button } from "antd";
import { Task } from "./TaskManagement";

const { TextArea } = Input;
const { Option } = Select;

interface NewTaskModalProps {
  visible: boolean;
  onClose: () => void;
  onCreateTask: (task: Task) => void;
}

const NewTaskModal: React.FC<NewTaskModalProps> = ({
  visible,
  onClose,
  onCreateTask,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const newTask: Task = {
        id: Date.now().toString(),
        title: values.title,
        description: values.description,
        // Add other fields as needed
      };
      onCreateTask(newTask);
      form.resetFields();
    });
  };

  return (
    <Modal
      title="New task"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="assignedTo" label="Assigned to">
          <Select>
            <Option value="Sharma Aastha">Sharma Aastha</Option>
            {/* Add more options as needed */}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="startDate" label="Starts on">
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="dueDate" label="Due date">
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewTaskModal;
