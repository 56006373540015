import styled from "@emotion/styled";
import { Card } from "antd";

export const SettingsContainer = styled.div`
  padding: 24px;
`;

export const SettingsCategoryTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const SettingsGrid = styled.div`
  margin-bottom: 32px;
`;

export const SettingCard = styled(Card)`
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ant-card-meta-title {
    font-size: 14px;
  }

  .ant-card-meta-avatar {
    font-size: 24px;
  }
`;
