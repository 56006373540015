/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { Layout, Table, Card, Button, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title, Text } = Typography;

interface Employee {
  fullName: string;
  gender: string;
  jobLevel: string;
  position: string;
  department: string;
  division: string;
  location: string;
}

interface GenderData {
  name: string;
  value: number;
  color: string;
}

const mockEmployees: Employee[] = [
  {
    fullName: "Berman Kathleen",
    gender: "Female",
    jobLevel: "Senior",
    position: "Middle PHP developer",
    department: "IT",
    division: "Europe",
    location: "Berlin",
  },
  {
    fullName: "Brand Timothy",
    gender: "Male",
    jobLevel: "Junior",
    position: "COO",
    department: "Executive",
    division: "Europe",
    location: "London",
  },
  // Add more mock employees...
];

const GenderReportPage: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  const [genderData, setGenderData] = useState<GenderData[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setEmployees(mockEmployees);
      setLoading(false);

      const male = mockEmployees.filter(
        (employee) => employee.gender === "Male"
      ).length;
      const female = mockEmployees.filter(
        (employee) => employee.gender === "Female"
      ).length;
      const notSpecified = mockEmployees.filter(
        (employee) => employee.gender !== "Male" && employee.gender !== "Female"
      ).length;

      setGenderData([
        { name: "Male", value: male, color: "#36A2EB" },
        { name: "Female", value: female, color: "#FF6384" },
        { name: "Not Specified", value: notSpecified, color: "#FFCE56" },
      ]);
    }, 1000); // Reduced delay for demonstration
  }, []);

  const columns = [
    { title: "Full name", dataIndex: "fullName", key: "fullName" },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Job level", dataIndex: "jobLevel", key: "jobLevel" },
    { title: "Position", dataIndex: "position", key: "position" },
    { title: "Department", dataIndex: "department", key: "department" },
    { title: "Division", dataIndex: "division", key: "division" },
    { title: "Location", dataIndex: "location", key: "location" },
  ];

  return (
    <Layout>
      <Content style={{ padding: "24px" }}>
        <Card>
          <Title level={3} style={{ margin: 0 }}>
            Gender profile
          </Title>
          <div>Report on the gender diversification of your company</div>

          <Button style={{ marginTop: "16px" }}>Filter</Button>

          <Card title="Gender Distribution" style={{ marginTop: "24px" }}>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={genderData}
                  cx="50%"
                  cy="50%"
                  innerRadius={0}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                >
                  {genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name, entry) => {
                    const total = genderData.reduce(
                      (sum, data) => sum + data.value,
                      0
                    );
                    const percentage = ((Number(value) / total) * 100).toFixed(
                      2
                    );
                    return `${percentage}%`;
                  }}
                />
                <Legend
                  formatter={(value, entry) => {
                    if (entry && entry.payload) {
                      const total = genderData.reduce(
                        (sum, data) => sum + data.value,
                        0
                      );
                      const percentage = (
                        (entry.payload.value / total) *
                        100
                      ).toFixed(2);
                      return `${value} ${percentage}%`;
                    }
                    return value;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Card>

          <Table
            columns={columns}
            dataSource={employees}
            loading={loading}
            pagination={{ pageSize: 10 }}
            style={{ marginTop: "24px" }}
          />

          <Text
            type="secondary"
            style={{ marginTop: "16px", display: "block" }}
          >
            Displaying 1 - {Math.min(10, employees.length)} of{" "}
            {employees.length} in total
          </Text>
        </Card>
      </Content>
    </Layout>
  );
};

export default GenderReportPage;
