import UserPool from "@pages/RegisterForm/UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { message } from "antd";
import React, { createContext, useState } from "react";

const AccountContext = createContext({
  authenticate: async (Username: string, Password: string) => {
    throw new Error("method not implemented");
  },
  getSession: async () => {
    throw new Error("method not implemented");
  },
  setStatus: (status: boolean) => {
    throw new Error("method not implemented");
  },
  logout: async () => {
    throw new Error("method not implemented");
  },
  status: false,
});

const Account = (props: any) => {
  const [status, setStatus] = useState(() => {
    return !!sessionStorage.getItem("sessionData");
  });

  const logout = async () => {
    await UserPool.getCurrentUser().signOut();
    sessionStorage.removeItem("sessionData");
    setStatus(false);
  };

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      }
    });
  };

  const authenticate = async (Username: string, Password: string) => {
    if (!Username || !Password) {
      throw new Error("Username and Password are required");
    }
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
          sessionStorage.setItem("sessionData", JSON.stringify(data));
          setStatus(true);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("new password required", data);
          message.info("New Password required");
          resolve(data);
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{ authenticate, getSession, logout, status, setStatus }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
