import React, { useState } from "react";
import { Modal, Form, Input, Select, Upload, Button, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";

interface NewAssetModalProps {
  visible: boolean;
  onClose: () => void;
  onCreateAsset: (asset: any) => void;
}

const { Dragger } = Upload;

const NewAssetModal: React.FC<NewAssetModalProps> = ({
  visible,
  onClose,
  onCreateAsset,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const handleCreate = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const newAsset = {
        ...values,
        image,
      };
      onCreateAsset(newAsset);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Modal
      visible={visible}
      title="Add Asset"
      okText="Create"
      cancelText="Cancel"
      onCancel={onClose}
      onOk={handleCreate}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          currency: "USD",
        }}
      >
        <Form.Item label="ID" name="id">
          <Input />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Category" name="category">
          <Select>
            <Select.Option value="Laptops">Laptops</Select.Option>
            <Select.Option value="Phones">Phones</Select.Option>
            <Select.Option value="Tablets">Tablets</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Serial Number" name="serialNumber">
          <Input />
        </Form.Item>
        <Form.Item
          label="Image"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger
            name="image"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            onChange={(info) => {
              if (info.file.status === "done") {
                setImage(info.file.originFileObj);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Drag here to upload or click here to browse
              <br />
              Max files: 1, max file size: 50MB.
              <br />
              Allowed file types: .jpeg, .jpg, .png.
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item label="Price" name="price">
          <Input addonBefore="$" />
        </Form.Item>
        <Form.Item label="Currency" name="currency">
          <Select>
            <Select.Option value="USD">
              USD - United States Dollar
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Location" name="location">
          <Input />
        </Form.Item>
        <Form.Item label="Division" name="division">
          <Input />
        </Form.Item>
        <Form.Item label="Department" name="department">
          <Input />
        </Form.Item>
        <Form.Item label="Warranty Expires On" name="warrantyExpiresOn">
          <Input type="date" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewAssetModal;
