import React, { useState, useEffect } from "react";
import { Layout, Typography, Tabs, Input, Button, Card, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NewAssetModal from "./NewAssetModal";

const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;

interface Asset {
  id: string;
  name: string;
  serialNumber: string;
  category: string;
  location: string;
  assignedTo: string;
}

const AssetManagement: React.FC = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setAssets([
        {
          id: "A35483",
          name: 'APPLE MACBOOK PRO 13,3" SILVER MUHQ2',
          serialNumber: "MUHQ2",
          category: "Laptops",
          location: "London",
          assignedTo: "",
        },
        {
          id: "A35683",
          name: "ASUS VIVOBOOK 15 X542UQ",
          serialNumber: "X542UQ",
          category: "Laptops",
          location: "Berlin",
          assignedTo: "",
        },
        {
          id: "A40593",
          name: "APPLE IPHONE 8 256GB GOLD",
          serialNumber: "8 256GB",
          category: "Phones",
          location: "London",
          assignedTo: "",
        },
        {
          id: "A53135",
          name: "Macbook Pro",
          serialNumber: "",
          category: "Laptops",
          location: "Berlin",
          assignedTo: "",
        },
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleNewAsset = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleAssetCreate = (newAsset: Asset) => {
    setAssets([...assets, newAsset]);
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "24px" }}>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Title level={2} style={{ margin: 0 }}>
              Assets
            </Title>
            <Tabs defaultActiveKey="my" style={{ marginBottom: 0 }}>
              <TabPane tab="My" key="my" />
              <TabPane tab="Team" key="team" />
              <TabPane tab="Company" key="company" />
            </Tabs>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Search placeholder="Search..." style={{ width: 200 }} />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleNewAsset}
            >
              New Asset
            </Button>
          </div>

          <Tabs defaultActiveKey="all">
            <TabPane tab={`All ${assets.length}`} key="all">
              <Table columns={columns} dataSource={assets} loading={loading} />
            </TabPane>
            <TabPane tab="Available" key="available" />
            <TabPane tab="Assigned" key="assigned" />
          </Tabs>
        </Card>
      </Content>
      <NewAssetModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onCreateAsset={handleAssetCreate}
      />
    </Layout>
  );
};

export default AssetManagement;
